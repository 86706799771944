<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-allergies"></i> Allergies & intolérances
    </h3>

    <div class="flex flex-col w-full">
      <div class="flex justify-between w-full">
        <div>
          <label class="mr-4 "
            >Souffrez-vous d'une allergie ou intolérance ?</label
          >
          <!-- toggle-button v-model="showAllergieBlock"  :sync="true" :labels="{checked: 'On', unchecked: 'Off'}"/ -->
        </div>
        <div>
          <p class="text-xs text-gray-500">
            (Cliquer sur <i class="fa fa-check"></i> pour sauvegarder)
          </p>
        </div>
      </div>
      <div class="walooododo">
        <!-- <span v-for="(cat, acatKey) in allergies"  v-if="acatKey != 'isActive'" :key="acatKey" :category="cat">{{cat}}</span> -->
        <category
          v-for="(cat, acatKey) in allergies"
          v-if="acatKey != 'isActive'"
          :key="acatKey"
          :category="cat"
          v-on:removeCategory="removeList"
          v-on:allergieSaved="AddAllergieSavedToList"
          :patient="patient"
          :patientAllergies="allergies[acatKey]"
        >
        </category>
      </div>
    </div>
  </div>
</template>

<script>
const Category = () => import("./category.vue");
export default {
  props: ["patient"],
  components: { Category },
  data() {
    return {
      showAllergieBlock: true,
      allergies: {}
    };
  },
  mounted() {
    var el = this;

    this.$store
      .dispatch("healthbook/GET_PATIENT_ALLERGIE_LIST", {
        patientId: this.patient._id
      })
      .then(function(response) {
        el.allergies = response.data;
        //console.log("AAEEEEEEEERERERERZERZERZEREZRZE", el.allergies)
        // el.showAllergieBlock = response.data.isActive
      });
  },
  watch: {
    showAllergieBlock: function(newVal) {
      const el = this;
      if (!newVal) {
        var vonf = confirm(
          "En ésactivant vous risquer de tout supprimer. \nVoulez-vous vraiement confirmer cette action ?"
        );
        if (vonf) {
          this.$store.dispatch("healthbook/REMOVE_ALL_PATIENT_ALLERGIE", {
            patientId: this.patient._id,
            onData: function() {
              for (var i = 0; i < el.allergies.length; i++) {
                el.allergies[i].patient = null;
              }
              el.$forceUpdate();
            }
          });
        } else {
          setTimeout(function() {
            el.showAllergieBlock = true;
          }, 200);
        }
      }
    }
  },
  methods: {
    AddAllergieSavedToList(allergie, category) {
      this.allergies[category.slug].list.push(allergie);
      this.$forceUpdate();
    },
    async removeList(allergyId, category) {
      if (this.allergies[category].list) {
        let indexRemv = -1;
        for (var i = this.allergies[category].list.length - 1; i >= 0; i--) {
          if (this.allergies[category].list[i]._id == allergyId) {
            indexRemv = i;
            break;
          }
        }
        if (indexRemv > -1) {
          this.allergies[category].list.splice(indexRemv, 1);
        }
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style></style>
